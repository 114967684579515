$light-text-color:#fff;
$dark-text-color:#28292b;

[data-theme='light']{
    --ngx-json-string : #ffa601;
    --ngx-json-number : #f34a77;    
    --ngx-json-boolean : #2f80a0;
    --ngx-json-date : #{$dark-text-color};
    --ngx-json-array : #{$dark-text-color};
    --ngx-json-object : #{$dark-text-color};
    --ngx-json-function : #{$dark-text-color};
    --ngx-json-null : #6e6d6d;
    --ngx-json-null-bg : transparent;
    --ngx-json-undefined : #7b7878;
    --ngx-json-toggler : #ff00bb;
    --ngx-json-key : #{$dark-text-color};
    --ngx-json-separator : #{$dark-text-color};
    --ngx-json-value : #{$dark-text-color};
    --ngx-json-undefined-key : #{$dark-text-color};
}

[data-theme='dark']{
    --ngx-json-string : #ffa601;
    --ngx-json-number : #f34a77;    
    --ngx-json-boolean : #2f80a0;
    --ngx-json-date : #{$light-text-color};
    --ngx-json-array : #{$light-text-color};
    --ngx-json-object : #{$light-text-color};
    --ngx-json-function : #{$light-text-color};
    --ngx-json-null : #6e6d6d;
    --ngx-json-null-bg : transparent;
    --ngx-json-undefined : #7b7878;
    --ngx-json-toggler : #ff00bb;
    --ngx-json-key : #{$light-text-color};
    --ngx-json-separator : #{$light-text-color};
    --ngx-json-value : #{$light-text-color};
    --ngx-json-undefined-key : #{$light-text-color};
}