@import 'bootstrap-scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

$bg-opacity: 1;

// Dark Theme
$dark-text-color: #ffffff;
$dark-gradient-from: #1e262b;
$dark-gradient-to: #000;
$dark-gradient-stops: #1e262b, #050809;
$dark-mainnet-primary: #176966;
$dark-mainnet-primary-sub: #329693;
$dark-testnet-primary: #77025a;
$dark-testnet-primary-sub: #e302ab;
$dark-prevnet-primary: #9a5116;
$dark-prevnet-primary-sub: #df760d;

// Light Theme
$light-text-color: #222a24;
$light-gradient-from: #ffffff;
$light-gradient-to: #acb4b5;
$light-gradient-stops: #679070, #79ab86;
$light-mainnet-primary: #176966;
$light-mainnet-primary-sub: #329693;
$light-testnet-primary: #77025a;
$light-testnet-primary-sub: #e302ab;
$light-prevnet-primary: #9a5116;
$light-prevnet-primary-sub: #df760d;


:root {
    --headerHeight: 70px;
    --footerHeight: 80px;
    --light-text-color: #{$light-text-color};
    --dark-text-color: #{$dark-text-color};


    --mainnet-primary: #{$light-mainnet-primary};
    --mainnet-primary-sub: #{$light-mainnet-primary-sub};
    --testnet-primary: #{$light-testnet-primary};
    --testnet-primary-sub: #{$light-testnet-primary-sub};
    --prevnet-primary: #{$light-prevnet-primary};
    --prevnet-primary-sub: #{$light-prevnet-primary-sub};

    [data-theme='dark'] & {
        
        --mainnet-primary: #{$dark-mainnet-primary};
        --mainnet-primary-sub: #{$dark-mainnet-primary-sub};
        --testnet-primary: #{$dark-testnet-primary};
        --testnet-primary-sub: #{$dark-testnet-primary-sub};
        --prevnet-primary: #{$dark-prevnet-primary};
        --prevnet-primary-sub: #{$dark-prevnet-primary-sub};
    }
}

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: 'Roboto';
    font-weight: 400;
    flex-direction: column;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    color:var(--light-text-color);
}
body {
    background-color: #ededed !important;
    background-image: linear-gradient(to bottom right, $light-gradient-from, $light-gradient-to) !important;
    background-attachment: fixed;
}

*:focus {
    outline: none;
}

@keyframes load-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes appear {
    to {
        opacity: 1;
    }
}

.nav-tabs {
    &.chain_detail {
        border: none;
        font-weight: 300;

        .nav-item {
            .nav-link {
                border: none;
                background-color: transparent;
                color: $light-text-color;
                cursor: pointer;
                opacity: 0.6;

                &.active {
                    opacity: 1;
                    color: $light-mainnet-primary-sub;
                    border-bottom: 2px solid $light-mainnet-primary;
                }

                &:hover {
                    opacity: 1;
                    color: $light-mainnet-primary-sub;
                }
            }
        }
    }
}

.network-theme {

    .transaction_state {
        color: #fff;
        background-color: transparent;
        border: none;

        &.success {
            border-color: #28a745;
            color: #28a745;
        }
    }

    .inner-content {

        position: relative;
        overflow: hidden;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.35;
            background-color: #fff;
        }
        &.empty::before{
            opacity: 0.1;
        }
    }

    &-mainnet {
        .main-color{
            color: $light-mainnet-primary;
        }
        .main-color-sub{
            color: $light-mainnet-primary-sub;
        }
        .badge {
            background-color: $light-mainnet-primary;
        }
        .nav-tabs {
            .nav-item {
                .nav-link {
                    &.active {
                        color: $light-mainnet-primary-sub;
                        border-bottom-color: $light-mainnet-primary;
                    }
                    &:hover {
                        color: $light-mainnet-primary-sub;
                    }
                }
            }
        }
    }

    &-testnet {

        .main-color{
            color: $light-testnet-primary;
        }
        .main-color-sub{
            color: $light-testnet-primary-sub;
        }
        .badge {
            background-color: $light-testnet-primary;
        }
        .inner-content {
            &::before {
                background-color: $light-testnet-primary;
            }
        }
        .nav-tabs {
            .nav-item {
                .nav-link {
                    &.active {
                        color: $light-testnet-primary-sub;
                        border-bottom-color: $light-testnet-primary;
                    }
                    &:hover {
                        color: $light-testnet-primary-sub;
                    }
                }
            }
        }
    }
}


[data-theme='dark'] {

    color:var(--dark-text-color);
    background-color: #141a1e !important;
    background-image: linear-gradient(to bottom right, $dark-gradient-from, $dark-gradient-to) !important;

    .nav-tabs {
        &.chain_detail {
            .nav-item {
                .nav-link {
                    color: $dark-text-color;
                }
            }
        }
    }
    
    .network-theme {
        .inner-content {
            &::before {
                opacity: 0.25;
            }
            &.empty::before{
                opacity: 0.15;
            }
        }
        &-mainnet {
            .main-color{
                color: $dark-mainnet-primary;
            }
            .main-color-sub{
                color: $dark-mainnet-primary-sub;
            }
            .badge {
                border-color: $dark-mainnet-primary;
            }
            .inner-content {
                &::before {
                    background-color: $dark-mainnet-primary;
                }
            }
            .nav-tabs {
                .nav-item {
                    .nav-link {
                        &.active {
                            color: $dark-mainnet-primary-sub;
                            border-bottom-color: $dark-mainnet-primary;
                        }
                        &:hover {
                            color: $dark-mainnet-primary-sub;
                        }
                    }
                }
            }
            .inner-content {
                &::before {
                    background-color: $dark-mainnet-primary-sub;
                }
            }
        }
    
        &-testnet {
            .main-color{
                color: $dark-testnet-primary;
            }
            .main-color-sub{
                color: $dark-testnet-primary-sub;
            }
            .badge {
                border-color: $dark-testnet-primary;
            }
            .inner-content {
                &::before {
                    background-color: $dark-testnet-primary;
                }
            }
            .nav-tabs {
                .nav-item {
                    .nav-link {
                        &.active {
                            color: $dark-testnet-primary-sub;
                            border-bottom-color: $dark-testnet-primary;
                        }
                        &:hover {
                            color: $dark-testnet-primary-sub;
                        }
                    }
                }
            }
            .inner-content {
                &::before {
                    background-color: $dark-testnet-primary-sub;
                }
            }
        }
    }

}
